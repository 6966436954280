import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as MasterAction from '../../../stores/master/MasterAction';
import RouteEnum from 'constants/RouteEnum';
import environment from 'environment';
import Moment from 'moment';
export default function AppFooter() {
  const dispatch = useDispatch();
  const sysUserData = useSelector((state) => state.master.sysUserData && JSON.parse(state.master.sysUserData));

  useEffect(() => {
    if (window.location.pathname !== RouteEnum.CreateQuestionnaire) {
      localStorage.setItem('survey-json', '{}');
    }
    if (localStorage.getItem('path') !== window.location.pathname) {
      localStorage.setItem('path', window.location.pathname);
      localStorage.setItem('reloadcount', 0);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (!sysUserData) {
      if (window.location.pathname !== '/') {
        dispatch(MasterAction.requestUserRole());
      }
    }
  }, [sysUserData]);

  return (
    <div className="app_footer" role="contentinfo">
      <p className="m-0">
        Copyright &copy; {environment.appBrandName} {Moment().year()}. All rights reserved
      </p>
    </div>
  );
}
