import { BaseModel } from 'sjs-base-model';
export default class CreateQuestionnaireModel extends BaseModel {
  statusCode = null;
  statusMessage = null;
  timeinMillis = null;
  data = [];
  formId = null;
  formStorageUniquePath = null;

  constructor(data) {
    super();
    this.update(data);
  }
}
