import styles from './Toasts.module.scss';

import React from 'react';
import { useSelector } from 'react-redux';
import ToastCard from '../toast-card/ToastCard';
import environment from 'environment';

export default function Toasts(props) {
  const toasts = useSelector((state) => state.toasts.items);

  if (toasts.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {toasts.map((model) =>
        environment.appModuleConfig.SHOW_ALL_API_FAILURE_ALERT ? (
          <ToastCard key={model.id} item={model} />
        ) : (
          environment.appModuleConfig.SHOW_API_FAILURE_ALERT_STATUS.includes(model.status) && <ToastCard key={model.id} item={model} />
        )
      )}
    </div>
  );
}
