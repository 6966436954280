/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(baseApi, appkey, app_name, app_version, currency_code, appModuleConfig, shipCode, appLanguage, brand, brandName) {
  return {
    route: {
      baseRoute: '',
    },
    api: {
      sysUserAuthenticate: `${baseApi.sysApi}/api/v1/sysUserAuthenticate`,
      userAuthenticate: `${baseApi.appCoreApi}/api/v1/userAuthenticate`,
      getServerDateTime: `${baseApi.controlCenterApi}/api/v1/serverDate`,
      getlanguageApi: `${baseApi.appCoreApi}/api/v1/languages`,
      getAppsList: `${baseApi.appCoreApi}/api/v1/smartforms/apps/QuestionnaireApp`,
      getDistinctInstanceName: `${baseApi.appCoreApi}/api/v1/smartforms/DistinctInstanceNames`,
      saveFormQuestionnaire: `${baseApi.appCoreApi}/api/v1/smartforms/coreFormsMaster`,
      getQuestionnaire: `${baseApi.appCoreApi}/api/v1/smartforms/coreFormsMaster`,
      getQuestionnaireAudits: `${baseApi.appCoreApi}/api/v1/smartforms/formsMasterAudit/formId/:id`,
      getActiveQuestionnaireForms: `${baseApi.appCoreApi}/api/v1/smartforms/coreFormsMaster/CurrentActive/appId/:appId`,
      getPublishedQuestionnaire: `${baseApi.appCoreApi}/api/v1/smartforms/coreFormsMaster/IsDraft/:isDraft`,
      publishQuestionnaire: `${baseApi.appCoreApi}/api/v1/smartforms/coreFormsMaster/Publish`,
    },
    isProduction: true,
    isDevelopment: false,
    isTesting: false,
    appName: app_name,
    appVersion: app_version,
    appsecretkey: appkey,
    // app_useraccess_roles: app_useraccess_roles,
    currencyCode: currency_code,
    appModuleConfig: appModuleConfig,
    baseApi: baseApi,
    shipCode: shipCode,
    appLanguage: appLanguage,
    appBrand: brand,
    appBrandName: brandName,
  };
}
