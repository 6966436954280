import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LogoCarnival from '../../../assets/dist/images/logo-carnival.png';
import { IoExitOutline } from 'react-icons/io5';
import { Link, useHistory, Redirect } from 'react-router-dom';
import RouteEnum from 'constants/RouteEnum';
import environment from 'environment';

export default function AppHeader(props) {
  const history = useHistory();

  return (
    <React.Fragment>
      {/* Header Section */}
      {localStorage.getItem('access_token') === null && <Redirect to={{ pathname: '/', state: { from: props.location } }} />}

      <div role="banner" className="app_header">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={8} md={4} sm={6} lg={4}>
              <h1>Form Builder</h1>
            </Col>
            <Col xs={12} md={4} sm={4} lg={4}>
              <Link to={RouteEnum.Home} className="appLogo">
                <img src={LogoCarnival} alt={environment.appBrandName} />
              </Link>
            </Col>
            <Col xs={4} md={4} sm={2} lg={4} className="app_sign_out">
              <div>
                <Link
                  onClick={() => {
                    history.push(RouteEnum.Login);
                  }}
                >
                  <IoExitOutline /> Sign Out
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* End Header Section */}
    </React.Fragment>
  );
}
