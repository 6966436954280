import * as CreateQuestionnaireAction from './CreateQuestionnaireAction';
import baseReducer from '../../utilities/BaseReducer';
import jwt_decode from 'jwt-decode';

export const initialState = {};

const CreateQuestionnaireReducer = baseReducer(initialState, {
  [CreateQuestionnaireAction.REQUEST_APP_LIST_FINISHED](state, action) {
    return {
      ...state,
      appsList: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_DISTINCT_INSTANCE_NAME_FINISHED](state, action) {
    return {
      ...state,
      instanceNames: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_SAVE_QUESTIONNAIREDATA_FINISHED](state, action) {
    return {
      ...state,
      createQuestionnaire: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_GET_QUESTIONNAIRE_FINISHED](state, action) {
    return {
      ...state,
      getQuestionnaire: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_SAVE_QUESTIONNAIREDATA_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      createQuestionnaire: undefined,
      publishQuestionnaireRes: undefined,
      updateQuestionnaireRes: undefined,
    };
  },
  [CreateQuestionnaireAction.REQUEST_GET_QUESTIONNAIRE_AUDIT_FINISHED](state, action) {
    return {
      ...state,
      getQuestionnaireAudit: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_GET_CURRENT_ACTIVE_QUESTIONNAIRE_FORM_FINISHED](state, action) {
    return {
      ...state,
      getActiveQuestionnaireForm: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_GET_PUBLISHED_QUESTIONNAIRE_FINISHED](state, action) {
    return {
      ...state,
      publishedQuestionnaire: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_PUBLISH_QUESTIONNAIRE_FINISHED](state, action) {
    return {
      ...state,
      publishQuestionnaireRes: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_UPDATE_QUESTIONNAIREDATA_FINISHED](state, action) {
    return {
      ...state,
      updateQuestionnaireRes: action.payload,
    };
  },
  [CreateQuestionnaireAction.REQUEST_CLEAR_AUDIT_FINISHED](state, action) {
    return {
      ...state,
      getQuestionnaireAudit: undefined,
    };
  },
});

export default CreateQuestionnaireReducer;
