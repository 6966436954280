import React, { Suspense, lazy, useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, HashRouter, Router } from 'react-router-dom';
import RouteEnum from '../constants/RouteEnum';
import LoadingIndicator from './components/loading-indicator/LoadingIndicator';
import Toasts from './components/toasts/Toasts';
import AppFooter from './components/appFooter/appFooter';
import AppHeader from './components/appHeader/appHeader';
import { useSelector } from 'react-redux';
import environment from 'environment';

const LoginPage = lazy(() => import('./login-page/LoginPage'));
const HomePage = lazy(() => import('./home-page/HomePage'));
const NotFoundPage = lazy(() => import('./not-found-page/NotFoundPage'));
const CreateQuestionnaire = lazy(() => import('./CreateQuestionnaire'));
const ListQuestionnaire = lazy(() => import('./ListQuestionnaire'));
const ViewAudits = lazy(() => import('./ViewAudits'));

export default function App(props) {
  const content = useSelector((state) => state);
  const [isLogged, setIsLogged] = useState(content.router.location.pathname);
  useEffect(() => {
    setIsLogged(content.router.location.pathname !== '/' ? true : false);
  }, [content.router.location.pathname]);
  return (
    <ConnectedRouter history={props.history}>
      {isLogged && <AppHeader props={props} />}
      <Suspense fallback={<LoadingIndicator isActive={true} />}>
        {/* <MainNav /> */}
        {/* <HashRouter> */}
        <Switch>
          <Route path={RouteEnum.Login} component={LoginPage} exact={true} />
          <Route path={RouteEnum.Home} component={HomePage} />
          <Route path={RouteEnum.CreateQuestionnaire} component={CreateQuestionnaire} />
          <Route path={RouteEnum.ListQuestionnaire} component={ListQuestionnaire} />
          <Route path={RouteEnum.ViewAduit} component={ViewAudits} />
          <Route component={NotFoundPage} />
        </Switch>
        {/* </HashRouter> */}
        {environment.appModuleConfig.SHOW_API_FAIURE_ALERT && <Toasts />}
      </Suspense>
      {isLogged && <AppFooter />}
    </ConnectedRouter>
  );
}
