import environment from 'environment';
import CreateQuestionnaireModel from './models/CreateQuestionnaireModel';
import * as EffectUtility from '../../utilities/EffectUtility';

export async function requestAppList(data) {
  const endpoint = environment.api.getAppsList;
  const requestConfig = {};
  return EffectUtility.getToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestDistinctInstanceName(data) {
  const endpoint = environment.api.getDistinctInstanceName;
  const requestConfig = {};
  return EffectUtility.getToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function saveQuestionnaireData(data) {
  const endpoint = environment.api.saveFormQuestionnaire;
  const requestConfig = {};
  return EffectUtility.postToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestGetQuestionnaire(data) {
  const endpoint = environment.api.getQuestionnaire;
  const requestConfig = {};
  return EffectUtility.getToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestClearCreateQuestionnaire(data) {
  return data;
}

export async function requestGetQuestionnaireAudit(data) {
  const endpoint = environment.api.getQuestionnaireAudits.replace(':id', data);
  const requestConfig = {};
  return EffectUtility.getToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestGetActiveQuestionnaireForm(data) {
  const endpoint = environment.api.getActiveQuestionnaireForms.replace(':appId', data);
  const requestConfig = {};
  return EffectUtility.getToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestGetPublishedQuestionnaire(data) {
  const endpoint = environment.api.getPublishedQuestionnaire.replace(':isDraft', false);
  const requestConfig = {};
  return EffectUtility.getToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestPublishQuestionnaire(data) {
  const endpoint = environment.api.publishQuestionnaire;
  const requestConfig = {};
  return EffectUtility.putToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestUpdateQuestionnaireData(data) {
  const endpoint = environment.api.saveFormQuestionnaire;
  const requestConfig = {};
  return EffectUtility.postToModel(CreateQuestionnaireModel, endpoint, data, requestConfig);
}

export async function requestClearAudit(data) {
  return data;
}
