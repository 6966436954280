// import styles from './ToastCard.module.scss';

import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { Button, Card } from 'semantic-ui-react';
import * as ToastsAction from '../../../stores/toasts/ToastsAction';
import buttonColorMap from '../../../constants/buttonColorMap';
import environment from 'environment';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import RouteEnum from 'constants/RouteEnum';
import { Link, useHistory, Redirect } from 'react-router-dom';

export default function ToastCard(props) {
  const [modalClose, setModalClose] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const onClickRemoveNotification = useCallback(
    (event, data) => {
      dispatch(ToastsAction.removeById(props.item.id));
    },
    [dispatch, props.item.id]
  );

  const buttonColor = buttonColorMap[props.item.type];

  // return (
  //   <Card key={props.item.id}>
  //     <Card.Content>
  //       <Card.Header content={"hi"} />
  //       <Card.Description content={"mess"} />
  //     </Card.Content>
  //     <Card.Content extra={true}>
  //       <Button color={buttonColor} onClick={onClickRemoveNotification}>
  //         Close
  //       </Button>
  //     </Card.Content>
  //   </Card>
  // );

  return (
    <>
      <Modal
        show={modalClose}
        onHide={() => {
          onClickRemoveNotification();
          setModalClose(false);
        }}
        backdrop="static"
        keyboard={false}
        centered
        className="modal-popup-notes"
      >
        <Modal.Header>
          <Modal.Title className="w-100">
            <h1 className="mb-0">{props.item.type}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="popup-sessions">
                <h2 style={{ textAlign: 'center' }}>
                  {props.item.message && props.item.message.includes('Cannot read') ? 'Error Requesting data' : props.item.message}
                </h2>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              window.location.pathname !== '/' && window.location.pathname !== '/home' && history.push(RouteEnum.Home);
              onClickRemoveNotification();
              setModalClose(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
