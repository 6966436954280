export const defaultLocale = 'en_GB';

export const fallbackLocale = 'en_GB';

export const supportedLocales = {
  en_GB: 'en_GB',
  de_DE: 'de_DE',
  ja_JP: 'ja_JP',
  en_AU: 'en_AU',
  en_US: 'en_US',
  zh_CN: 'zh_CN',
};
