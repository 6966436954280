import * as ActionUtility from '../../utilities/ActionUtility';
import * as ToastsEffect from './ToastsEffect';

import uuid from 'uuid/v4';

export const ADD_TOAST = 'ToastsAction.ADD_TOAST';
export const ADD_ERROR = 'ToastsAction.ADD_ERROR';
export const ADD_ERROR_FINISHED = 'ToastsAction.ADD_ERROR_FINISHED';

export function add(message, type, status) {
  return ActionUtility.createAction(ADD_TOAST, {
    message,
    type,
    status,
    id: uuid(),
  });
}

export const REMOVE_TOAST = 'ToastsAction.REMOVE_TOAST';

export function removeById(toastId) {
  return ActionUtility.createAction(REMOVE_TOAST, toastId);
}

export function store_error(data = null, type = null, status) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, ADD_ERROR, ToastsEffect.store_data, { message: data, type, status, id: uuid() });
  };
}
