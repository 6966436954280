import * as CreateQuestionnaireEffect from './CreateQuestionnaireEffect';
import * as ActionUtility from '../../utilities/ActionUtility';

export const REQUEST_APP_LIST = 'CreateQuestionnaireAction.REQUEST_APP_LIST';
export const REQUEST_APP_LIST_FINISHED = 'CreateQuestionnaireAction.REQUEST_APP_LIST_FINISHED';

export function requestAppList(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_APP_LIST, CreateQuestionnaireEffect.requestAppList, data);
  };
}

export const REQUEST_DISTINCT_INSTANCE_NAME = 'CreateQuestionnaireAction.REQUEST_DISTINCT_INSTANCE_NAME';
export const REQUEST_DISTINCT_INSTANCE_NAME_FINISHED = 'CreateQuestionnaireAction.REQUEST_DISTINCT_INSTANCE_NAME_FINISHED';

export function requestDistinctInstanceName(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_DISTINCT_INSTANCE_NAME, CreateQuestionnaireEffect.requestDistinctInstanceName, data);
  };
}

export const REQUEST_SAVE_QUESTIONNAIREDATA = 'CreateQuestionnaireAction.REQUEST_SAVE_QUESTIONNAIREDATA';
export const REQUEST_SAVE_QUESTIONNAIREDATA_FINISHED = 'CreateQuestionnaireAction.REQUEST_SAVE_QUESTIONNAIREDATA_FINISHED';

export function saveQuestionnaireData(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_SAVE_QUESTIONNAIREDATA, CreateQuestionnaireEffect.saveQuestionnaireData, data);
  };
}

export const REQUEST_GET_QUESTIONNAIRE = 'CreateQuestionnaireAction.REQUEST_GET_QUESTIONNAIRE';
export const REQUEST_GET_QUESTIONNAIRE_FINISHED = 'CreateQuestionnaireAction.REQUEST_GET_QUESTIONNAIRE_FINISHED';

export function requestGetQuestionnaire(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_QUESTIONNAIRE, CreateQuestionnaireEffect.requestGetQuestionnaire, data);
  };
}

export const REQUEST_SAVE_QUESTIONNAIREDATA_CLEAR = 'CreateQuestionnaireAction.REQUEST_SAVE_QUESTIONNAIREDATA_CLEAR';
export const REQUEST_SAVE_QUESTIONNAIREDATA_CLEAR_FINISHED = 'CreateQuestionnaireAction.REQUEST_SAVE_QUESTIONNAIREDATA_CLEAR_FINISHED';

export function requestClearCreateQuestionnaire(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_SAVE_QUESTIONNAIREDATA_CLEAR,
      CreateQuestionnaireEffect.requestClearCreateQuestionnaire,
      data
    );
  };
}

export const REQUEST_GET_QUESTIONNAIRE_AUDIT = 'CreateQuestionnaireAction.REQUEST_GET_QUESTIONNAIRE_AUDIT';
export const REQUEST_GET_QUESTIONNAIRE_AUDIT_FINISHED = 'CreateQuestionnaireAction.REQUEST_GET_QUESTIONNAIRE_AUDIT_FINISHED';

export function requestGetQuestionnaireAudit(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_GET_QUESTIONNAIRE_AUDIT, CreateQuestionnaireEffect.requestGetQuestionnaireAudit, data);
  };
}

export const REQUEST_GET_CURRENT_ACTIVE_QUESTIONNAIRE_FORM = 'CreateQuestionnaireAction.REQUEST_GET_CURRENT_ACTIVE_QUESTIONNAIRE_FORM';
export const REQUEST_GET_CURRENT_ACTIVE_QUESTIONNAIRE_FORM_FINISHED =
  'CreateQuestionnaireAction.REQUEST_GET_CURRENT_ACTIVE_QUESTIONNAIRE_FORM_FINISHED';

export function requestGetActiveQuestionnaireForm(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_GET_CURRENT_ACTIVE_QUESTIONNAIRE_FORM,
      CreateQuestionnaireEffect.requestGetActiveQuestionnaireForm,
      data
    );
  };
}

export const REQUEST_GET_PUBLISHED_QUESTIONNAIRE = 'CreateQuestionnaireAction.REQUEST_GET_PUBLISHED_QUESTIONNAIRE';
export const REQUEST_GET_PUBLISHED_QUESTIONNAIRE_FINISHED = 'CreateQuestionnaireAction.REQUEST_GET_PUBLISHED_QUESTIONNAIRE_FINISHED';

export function requestGetPublishedQuestionnaire(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(
      dispatch,
      REQUEST_GET_PUBLISHED_QUESTIONNAIRE,
      CreateQuestionnaireEffect.requestGetPublishedQuestionnaire,
      data
    );
  };
}

export const REQUEST_PUBLISH_QUESTIONNAIRE = 'CreateQuestionnaireAction.REQUEST_PUBLISH_QUESTIONNAIRE';
export const REQUEST_PUBLISH_QUESTIONNAIRE_FINISHED = 'CreateQuestionnaireAction.REQUEST_PUBLISH_QUESTIONNAIRE_FINISHED';

export function requestPublishQuestionnaire(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_PUBLISH_QUESTIONNAIRE, CreateQuestionnaireEffect.requestPublishQuestionnaire, data);
  };
}

export const REQUEST_UPDATE_QUESTIONNAIREDATA = 'CreateQuestionnaireAction.REQUEST_UPDATE_QUESTIONNAIREDATA';
export const REQUEST_UPDATE_QUESTIONNAIREDATA_FINISHED = 'CreateQuestionnaireAction.REQUEST_UPDATE_QUESTIONNAIREDATA_FINISHED';

export function requestUpdateQuestionnaireData(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_UPDATE_QUESTIONNAIREDATA, CreateQuestionnaireEffect.requestUpdateQuestionnaireData, data);
  };
}

export const REQUEST_CLEAR_AUDIT = 'CreateQuestionnaireAction.REQUEST_CLEAR_AUDIT';
export const REQUEST_CLEAR_AUDIT_FINISHED = 'CreateQuestionnaireAction.REQUEST_CLEAR_AUDIT_FINISHED';

export function requestClearAudit(data = null) {
  return async (dispatch, getState) => {
    await ActionUtility.createThunkEffect(dispatch, REQUEST_CLEAR_AUDIT, CreateQuestionnaireEffect.requestClearAudit, data);
  };
}
